import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo'; 
import PageHeader from '../components/page-header';
import JoinUs from '../components/join';

import bg from '../images/aboutpage.jpg';
import logo from '../images/logo.png';
import img1 from '../images/img1.jpg';

const AboutPage = () => {
  return (
    <Layout lightheader>
      <SEO title="Nosotros" />
      <div className="page about-page">
        <PageHeader bg={bg} />
        <div className="wrapper content">
          <h1>El instituto</h1>
          <div className="row">
            <div className="col col-50">
              <p>
                Desde hace 40 años el Instituto Hidalgo se ha esforzado a diario por ofrecer a nuestra Comunidad educativa la mejor formación tanto académica como humana, buscando con un esfuerzo conjunto formar a hombres y mujeres comprometidos en lo social, lo profesional y personal. Para lograrlo, contamos con un <b>personal docente altamente capacitado</b>, quien es cuidadosamente seleccionado, no sólo por su currículo, sino también, por el hecho de creer en la filosofía de la escuela y practicarla diariamente.
              </p>
              <p>
              Para nosotros lo más importante es que nuestros alumnos se desenvuelvan en un ambiente acogedor de manera que sienta <b>a su escuela como un lugar agradable y cómodo</b>, donde todos y cada uno de nuestros alumnos son reconocidos por su nombre y no como una matrícula más, es por esto que nuestra escuela fue diseñada pensando en la seguridad y bienestar de nuestros niños.
              </p>
            </div>
            <div className="col-50 img-container">
              <img src={logo} alt=""/>
            </div>
          </div>
          <div className="row">
            <div className="quote">
              El INSTITUTO HIDALGO BILINGÜE fue fundado en septiembre de 1977. Desde sus inicios el Instituto se preocupó por ofrecer a sus alumnos una educación de calidad y sobretodo, siempre se ha interesado por inculcar en los educandos valores que formen hombres y mujeres comprometidos con el mundo en el que vivimos.
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-50 img-container photo">
              <img src={img1} alt=""/>
            </div>
            <div className="col-50 col">
              <h2>Somos una escuela bilingüe</h2>
              <p>
                Somos una <b>escuela 100% Bilingüe</b> donde se busca que los alumnos a lo largo de 9 años en el Instituto salgan hablando, entendiendo y escribiendo el INGLÉS. Nuestras clases son 100% en Inglés, excepto en los niveles de Preescolar ya que los alumnos no han tenido contacto con el idioma y se utiliza un poco el español cuando es necesario.
              </p>
              <p>
                En el Instituto Hidalgo estamos seguros de la calidad académica que ofrecemos, por esta razón sabemos que al término de sus estudios de Nivel Primaria, los alumnos podrán ingresar al nivel avanzado de Inglés en la Secundaria de su elección.
              </p>
            </div>
          </div>
        </div>
        <JoinUs></JoinUs>
      </div>
    </Layout>
  )
}

export default AboutPage;